@import "styles/mixins";

.leftContainer {
  @include mobile {
    display: none;
  }
}

.rightContainer {
  display: flex;
  justify-content: flex-end;

  @include mobile {
    justify-content: flex-start;
    padding-top: 5rem;
  }

  .rightContent {
    width: 75%;
    height: 90%;

    @include mobile {
      width: 90%;
      height: unset;
    }
  }
}