@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .createButton {
    display: flex;
    width: 50%;
    white-space: nowrap;
    justify-content: center;
    padding: 1.2rem 0;
    align-items: center;
    @include tablet {
      width: 80%;
    }
  }

  svg {
    margin-left: 0.8rem;
    fill: var(--color-white);
    width: 1.2rem;
    height: 1.2rem;
  }

  .errorMessage {
    color: var(--color-rust);
    margin-top: 1rem;
  }
}
