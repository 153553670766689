.title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--color-black);
  text-align: center;
}

.subtitle {
  color: var(--color-forest);
  font-weight: 400;
  font-size: 1rem;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
