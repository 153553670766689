@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 769px) {
    @content;
  }
}
