
.container {
  .backButton {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
    color: var(--color-black);
  }

  .subtitle {
    color: var(--color-forest);
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 3rem;
  }

  .loader {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}