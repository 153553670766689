@import 'styles/mixins';

.container {
  padding: 0.5rem;

  .header {
    margin-bottom: 3.5rem;
    @include mobile {
      margin-bottom: 1rem;
    }
    @include tablet {
      margin-bottom: 1rem;
    }
  }

  .form {
    margin-bottom: 2.5rem;
    @include mobile {
      margin-bottom: 1rem;
    }
    @include tablet {
      margin-bottom: 1rem;
    }
  }

  .terms {
    margin-bottom: 2.5rem;
  }
}
