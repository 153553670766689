@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  & > div {
    width: 100%;
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
