@import "styles/mixins";

.inputTitleContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;

  .inputTitle {
    color: var(--color-iron);
  }
}

.inputContainer {
  margin-bottom: 1.5rem;

  .baseInput {
    border: 1px solid var(--color-cloud);

    &::placeholder {
      color: var(--color-concrete);
    }

    @include mobile {
      width: 100%;
    }
  }
}