.container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}