.inputs {
  display: flex !important;
  gap: 0.5rem;
  max-width: 24rem;
  margin: auto;

  input {
    background-color: var(--color-cotton);
    color: var(--color-forest);
    text-align: center;
    height: 6rem;
    width: 100%;
    border-radius: 2rem;
    font-size: 1.8rem;
    outline: none;
    border: 1px solid var(--color-cloud);
    flex: 1;
  }

  input:focus {
    border: 1px solid var(--color-forest);
    background-color: var(--color-cotton);
  }

}

.label {
  display: block;
  margin-top: 2rem;
  color: var(--color-rust);
  text-align: center;
}