.container {
  position: relative;

  .label {
    font-size: 13px;
  }

  .placeholder {
    width: 100%;
    background-color: #fff;
    position: absolute;
    left: 45px;
    bottom: 15px;
    z-index: 10;
    color: var(--color-concrete);
  }

  .placeholder .focused {
    display: none;
  }
}
