@keyframes bg-move {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(calc(-100% + 50vw));
  }
  
  100% {
    transform: translateX(0%);
  }
}

.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-cotton);

  .topContainer {
    flex: 1;

    .title {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 1rem;
      padding: 4rem 2rem;
    }
  }

  .imagesContainer {
    flex: 1;
    position: relative;

    .image {
      justify-self: flex-end;
      object-fit: contain;
      position: absolute;
      width: auto;
      bottom: 0;

      &.city {
        top: 0;
        height: 170%;
        min-height: 170%;
        animation: 100s linear bg-move infinite;
        animation-direction: alternate;
      }

      &.bushes {
        bottom: -10rem;
        height: 100%;
        min-height: 100%;
        animation: 100s linear bg-move infinite;
      }
    }
  }
}


